.form-open {
  top: 50vh;
}

@media (max-width: 1024px) {
  .form-open {
    top: calc(100vh - 1.5rem);
  }
}

@media (max-width: 991px) {
  .form-open {
    top: calc(100vh - 1.7rem);
  }
}

@media (max-width: 767px) {
  .form-open {
    top: calc(100vh - 1.4rem);
  }
}

.section1 {
  position: relative;
  height: 100vh;
}

.section1 .swiper {
  height: 100%;
}

.section1 .swiper::before, .section1 .swiper::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
  pointer-events: none;
}

.section1 .swiper::before {
  height: 30%;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(11, 21, 72, 0.7), rgba(11, 21, 72, 0));
}

.section1 .swiper::after {
  height: 65%;
  bottom: 0;
  background-image: linear-gradient(to top, #0b1548, rgba(11, 21, 72, 0));
}

.section1 .swiper .swiper-slide {
  overflow: hidden;
}

.section1 .swiper .swiper-slide b {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .swiper .swiper-pagination {
  text-align: left;
  width: 100%;
  top: auto !important;
  display: flex;
  bottom: 0.7rem !important;
  padding-left: 4.16vw;
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-pagination {
    padding-left: 0.3rem;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border-style: solid;
  border-color: #fff;
  opacity: 1;
  transition: all .3s;
  margin: 0 0.05rem;
  border-width: 0.01rem;
  width: 0.12rem;
  height: 0.12rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    border-width: 1px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 10px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #4dc0e8;
  background-color: #4dc0e8;
  box-shadow: 0 0 0.1rem #4dc0e8;
}

.section1 .banner-t {
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 50%;
  top: auto;
  transform: translateY(50%);
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .section1 .banner-t {
    bottom: 70%;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t {
    bottom: auto;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t {
    top: 25%;
  }
}

@media (max-width: 1024px) {
  .section1 .banner-t {
    transform: translateY(0%);
  }
}

@media (max-width: 1024px) {
  .section1 .banner-t {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t {
    font-size: 1.1rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t {
    font-size: 0.66rem;
  }
}

.section1 .banner-t span {
  display: block;
}

.section1 .zhanhui {
  position: absolute;
  z-index: 2;
  white-space: nowrap;
  padding-left: 0.2rem;
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

@media (max-width: 1024px) {
  .section1 .zhanhui {
    top: 40%;
  }
}

@media (max-width: 767px) {
  .section1 .zhanhui {
    top: auto;
  }
}

@media (max-width: 767px) {
  .section1 .zhanhui {
    bottom: 10%;
  }
}

@media (max-width: 1024px) {
  .section1 .zhanhui {
    transform: translateX(-50%);
  }
}

.section1 .zhanhui .t1 {
  font-family: 'roboto_bold';
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .section1 .zhanhui .t1 {
    font-size: 0.7rem;
  }
}

@media (max-width: 991px) {
  .section1 .zhanhui .t1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section1 .zhanhui .t1 {
    font-size: 0.5rem;
  }
}

.section1 .zhanhui .t1 span {
  background-color: #4dc0e8;
  color: #122057;
  border-radius: 5px;
  padding: 0 0.1rem;
}

.section1 .zhanhui .t2 {
  color: #cdf2ff;
  line-height: 1.4;
  font-size: 0.35rem;
  margin-bottom: 0.6rem;
}

@media (max-width: 1024px) {
  .section1 .zhanhui .t2 {
    font-size: 0.45rem;
  }
}

@media (max-width: 991px) {
  .section1 .zhanhui .t2 {
    font-size: 0.55rem;
  }
}

@media (max-width: 767px) {
  .section1 .zhanhui .t2 {
    font-size: 0.4rem;
  }
}

.section1 .zhanhui .t3 {
  font-family: 'roboto_bold';
  line-height: 1.9;
  border-left: 3px solid #4dc0e8;
  padding-left: 0.15rem;
}

@media (max-width: 1024px) {
  .section1 .zhanhui .t3 {
    padding-left: 15px;
  }
}

.section1 .scroll-icon {
  border: 2px solid #4dc0e8;
  position: absolute;
  z-index: 10;
  border-radius: 50px;
  overflow: hidden;
  transform: translateX(-50%);
  bottom: 0.7rem;
  right: 4.16vw;
  width: 18px;
  height: 24px;
}

@media (max-width: 1024px) {
  .section1 .scroll-icon {
    display: none;
  }
}

@media (max-width: 767px) {
  .section1 .scroll-icon {
    right: 0.3rem;
  }
}

.section1 .scroll-icon::after {
  content: '';
  display: block;
  background-color: #4dc0e8;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  animation: rain 2s infinite;
  width: 2px;
  height: 6px;
}

@keyframes rain {
  0% {
    transform: translateY(3px);
    opacity: 1;
  }
  80% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.top-circle {
  pointer-events: none;
}

.top-circle .circle {
  position: absolute;
  border-radius: 50%;
  border: 1px solid #2aaaff;
  z-index: 1;
  transition: all 10s linear;
  top: 0;
  right: 0;
  width: 168.38vw;
  height: 147.76vw;
}

@media (max-width: 767px) {
  .top-circle .circle {
    transition: all 4s linear;
  }
}

@media (max-width: 1024px) {
  .top-circle .circle {
    top: 18%;
  }
}

@media (max-width: 991px) {
  .top-circle .circle {
    top: 18%;
  }
}

@media (max-width: 767px) {
  .top-circle .circle {
    top: 14%;
  }
}

@media (max-width: 1024px) {
  .top-circle .circle {
    right: -30%;
  }
}

@media (max-width: 1024px) {
  .top-circle .circle {
    width: 202.38vw;
  }
}

@media (max-width: 767px) {
  .top-circle .circle {
    width: 264.38vw;
  }
}

@media (max-width: 1024px) {
  .top-circle .circle {
    height: 184.76vw;
  }
}

@media (max-width: 767px) {
  .top-circle .circle {
    height: 298.76vw;
  }
}

.top-circle .circle.circle1 {
  opacity: .2;
  transform: translate3d(0%, -59%, 0);
}

.top-circle .circle.circle2 {
  opacity: .3;
  transform: translate3d(-17%, -51%, 0);
}

.top-circle .circle.circle3 {
  opacity: .1;
  transform: translate3d(-25%, -40%, 0);
}

.section2 {
  position: relative;
  z-index: 6;
  justify-content: space-between;
  display: flex;
  padding-top: 0.6rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 991px) {
  .section2 {
    display: block;
  }
}

@media (max-width: 767px) {
  .section2 {
    margin-bottom: 1rem;
  }
}

.section2 .left {
  flex-shrink: 0;
  font-family: 'roboto_medium';
  width: 33%;
  font-size: 0.7rem;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section2 .left {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section2 .left {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    font-size: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    margin-bottom: 0.6rem;
  }
}

.section2 .right {
  flex-shrink: 0;
  width: 59.6%;
}

@media (max-width: 991px) {
  .section2 .right {
    width: 100%;
  }
}

.section2 .right .t {
  line-height: 1.75;
}

.section2 .right .t p {
  margin-bottom: 0.4rem;
}

.section2 .right .link {
  margin-top: 0.5rem;
}

.section4 {
  position: relative;
  z-index: 8;
  overflow: hidden;
  height: 100vh;
}

.section4 .sec4-title {
  position: absolute;
  font-family: 'roboto_medium';
  z-index: 5;
  top: 7.3vw;
  left: 4.16vw;
  font-size: 0.7rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-title {
    top: 28.3vw;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-title {
    left: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section4 .sec4-title {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-title {
    font-size: 0.6rem;
  }
}

.section4 .sect-tab {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  align-items: center;
  display: flex;
  width: 43.22vw;
}

@media (max-width: 1024px) {
  .section4 .sect-tab {
    display: none;
  }
}

.section4 .sect-tab::before, .section4 .sect-tab::after {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  background-color: rgba(37, 50, 124, 0.63);
  pointer-events: none;
  width: 61.66vw;
  height: 96vw;
}

.section4 .sect-tab::before {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 153, 255, 0.68);
  transition: width .5s,height .5s,transform .5s;
}

.section4 .sect-tab .text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'roboto_bold';
  font-size: 0.35rem;
  padding-left: 6.7vw;
}

@media (max-width: 1024px) {
  .section4 .sect-tab .text {
    font-size: 0.45rem;
  }
}

@media (max-width: 991px) {
  .section4 .sect-tab .text {
    font-size: 0.55rem;
  }
}

@media (max-width: 767px) {
  .section4 .sect-tab .text {
    font-size: 0.4rem;
  }
}

.section4 .sect-tab .text a {
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  transition: color .3s;
  padding-bottom: 0.1rem;
}

.section4 .sect-tab .text a::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform .3s;
  height: 0.03rem;
}

@media (max-width: 1024px) {
  .section4 .sect-tab .text a::after {
    height: 2px;
  }
}

.section4 .sect-tab .text a:not(:last-child) {
  margin-bottom: 0.25rem;
}

.section4 .sect-tab .text a.hover {
  color: #fff;
}

.section4 .sect-tab .text a.hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}

.section4 .sect-tab.active::before {
  transform: translateY(-50%) translateX(-2.5%);
  width: 54.42vw;
  height: 81.51vw;
}

.section4 .swiper {
  height: 100%;
}

.section4 .swiper .swiper-slide {
  position: relative;
}

.section4 .swiper .swiper-slide .img {
  height: 100%;
  position: relative;
}

.section4 .swiper .swiper-slide .img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(6, 14, 52, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.section4 .swiper .swiper-slide .text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-sizing: border-box;
  padding-top: 17.96vw;
  padding-left: 4.16vw;
  padding-right: 0;
  width: 50%;
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-slide .text {
    padding-top: 45vw;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-slide .text {
    padding-top: 60vw;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-slide .text {
    padding-left: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-slide .text {
    padding-right: 4.16vw;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-slide .text {
    padding-right: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-slide .text {
    width: 100%;
  }
}

.section4 .swiper .swiper-slide .text .title {
  font-family: 'roboto_bold';
  font-size: 0.45rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-slide .text .title {
    font-size: 0.55rem;
  }
}

@media (max-width: 991px) {
  .section4 .swiper .swiper-slide .text .title {
    font-size: 0.65rem;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-slide .text .title {
    font-size: 0.4rem;
  }
}

.section4 .swiper .swiper-slide .text .desc {
  flex-wrap: wrap;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  width: 90%;
  margin-bottom: 0.8rem;
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-slide .text .desc {
    width: 100%;
  }
}

.section4 .swiper .swiper-btn {
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: .7rem;
  right: .3rem;
  display: none;
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-btn {
    display: flex;
  }
}

.section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
  position: static;
  margin: 0 auto;
  border-radius: 50px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color .3s, background-color .3s;
  width: 0.43rem;
  height: 0.43rem;
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    width: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    width: 0.78rem;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    width: 0.7rem;
  }
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    height: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    height: 0.78rem;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-btn .swiper-button-prev, .section4 .swiper .swiper-btn .swiper-button-next {
    height: 0.7rem;
  }
}

.section4 .swiper .swiper-btn .swiper-button-prev::after, .section4 .swiper .swiper-btn .swiper-button-next::after {
  font-family: 'iconfont';
  font-size: 0.18rem;
}

@media (max-width: 1024px) {
  .section4 .swiper .swiper-btn .swiper-button-prev::after, .section4 .swiper .swiper-btn .swiper-button-next::after {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section4 .swiper .swiper-btn .swiper-button-prev::after, .section4 .swiper .swiper-btn .swiper-button-next::after {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-btn .swiper-button-prev::after, .section4 .swiper .swiper-btn .swiper-button-next::after {
    font-size: 0.3rem;
  }
}

.section4 .swiper .swiper-btn .swiper-button-prev:hover, .section4 .swiper .swiper-btn .swiper-button-next:hover {
  background-color: #4dc0e8;
}

.section4 .swiper .swiper-btn .swiper-button-prev {
  margin-right: 0.3rem;
}

@media (max-width: 767px) {
  .section4 .swiper .swiper-btn .swiper-button-prev {
    margin-right: 0.2rem;
  }
}

.section4 .swiper .swiper-btn .swiper-button-prev::after {
  content: '\e746';
}

.section4 .swiper .swiper-btn .swiper-button-next::after {
  content: '\e748';
}

.section5 {
  position: relative;
  z-index: 9;
  padding-top: 1.6rem;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .section5 {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-bottom: 0.6rem;
  }
}

.section5 .bg {
  position: absolute;
  pointer-events: none;
  border-radius: 100%;
  background-image: radial-gradient(circle, #25327c, rgba(37, 50, 124, 0));
  box-shadow: 0 0 5rem rgba(37, 50, 124, 0.7);
  left: -20%;
  top: -20%;
  width: 6.9rem;
  height: 6.9rem;
}

@media (max-width: 991px) {
  .section5 .bg {
    left: -25%;
  }
}

@media (max-width: 767px) {
  .section5 .bg {
    left: -50%;
  }
}

@media (max-width: 1024px) {
  .section5 .bg {
    width: 8rem;
  }
}

@media (max-width: 991px) {
  .section5 .bg {
    width: 9rem;
  }
}

@media (max-width: 1024px) {
  .section5 .bg {
    height: 8rem;
  }
}

@media (max-width: 991px) {
  .section5 .bg {
    height: 9rem;
  }
}

.section5 .sec5-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.section5 .sec5-title h2 {
  font-family: 'roboto_medium';
  font-size: 0.7rem;
}

@media (max-width: 1024px) {
  .section5 .sec5-title h2 {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-title h2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .section5 .sec5-title h2 {
    font-size: 0.6rem;
  }
}

.section5 .sec5-title .more {
  line-height: 1.4;
  font-family: 'roboto_medium';
}

.section5 .sec5-title .more a {
  display: inline-block;
  position: relative;
}

.section5 .sec5-title .more a::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #4dc0e8;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform .3s;
  height: 0.02rem;
}

@media (max-width: 1024px) {
  .section5 .sec5-title .more a::after {
    height: 2px;
  }
}

.section5 .sec5-title .more a:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}

.section5 ul {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section5 ul {
    display: block;
  }
}

.section5 ul li {
  position: relative;
  z-index: 1;
  border-top: 1px solid rgba(77, 192, 232, 0.3);
  width: 47.15%;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .section5 ul li {
    width: 100%;
  }
}

.section5 ul li::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #4dc0e8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform .3s;
  height: 0.02rem;
}

@media (max-width: 1024px) {
  .section5 ul li::after {
    height: 2px;
  }
}

.section5 ul li .title, .section5 ul li .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: transform .3s;
}

.section5 ul li .title {
  font-family: 'roboto_bold';
  font-size: 0.3rem;
  margin-bottom: 0.35rem;
}

@media (max-width: 1024px) {
  .section5 ul li .title {
    font-size: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section5 ul li .title {
    font-size: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li .title {
    font-size: 0.38rem;
  }
}

.section5 ul li .desc {
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.75;
}

.section5 ul li:hover::after {
  transform-origin: left center;
  transform: scaleX(1);
}
