@import "../../assets/styles/utils";

.form-open{
  @include res(top,50vh,(md:calc(100vh - 1.5rem),sm:calc(100vh - 1.7rem),xs:calc(100vh - 1.4rem)));
}
.section1{
  position: relative;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    &::before,&::after{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }
    &::before{
      height: 30%;
      top: 0;
      background-image: linear-gradient(to bottom,rgba($color-main,.7),rgba($color-main,0));
    }
    &::after{
      height: 65%;
      bottom: 0;
      background-image: linear-gradient(to top,rgba($color-main,1),rgba($color-main,0));
    }
    .swiper-slide{
      overflow: hidden;
      b{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
    .swiper-pagination{
      text-align: left;
      width: 100%;
      top: auto !important;
      display: flex;
      @include res(bottom,.7rem !important);
      @include res(padding-left,4.16vw,(xs:.3rem));
      .swiper-pagination-bullet{
        background-color: transparent;
        border-style: solid;
        border-color: #fff;
        opacity: 1;
        transition: all .3s;
        @include res(margin,0 .05rem);
        @include res(border-width,.01rem,(md:1px));
        @include res(width,.12rem,(md:10px));
        @include res(height,.12rem,(md:10px));
        &.swiper-pagination-bullet-active{
          border-color: $color-main-light;
          background-color: $color-main-light;
          box-shadow: 0 0 .1rem $color-main-light;
        }
      }
    }
  }
  .banner-t{
    position: absolute;
    left: 0;
    z-index: 1;
    @include res(text-align,null,(md:center));
    @include res(bottom, 50%,(md:70%,xs:auto));
    @include res(top, auto,(xs:25%));
    @include res(transform, translateY(50%),(md:translateY(0%)));
    @include res(font-size, .9rem,(md:1rem,sm:1.1rem,xs:.66rem));
    span{
      display: block;
    }
  }
  .zhanhui{
    position: absolute;
    z-index: 2;
    white-space: nowrap;
    @include res(padding-left,.2rem);
    @include res(top,50%,(md:40%,xs:auto));
    @include res(left,50%);
    @include res(bottom,auto,(xs:10%));
    @include res(transform,translateY(-50%),(md:translateX(-50%)));
    .t1{
      font-family: 'roboto_bold';
      @include res(font-size, .6rem,(md:.7rem,sm:.8rem,xs:.5rem));
      @include res(margin-bottom,.5rem);
      span{
        background-color: $color-main-light;
        color: #122057;
        border-radius: 5px;
        @include res(padding,0 .1rem);
      }
    }
    .t2{
      color: #cdf2ff;
      line-height: 1.4;
      @include res(font-size,.35rem,(md:.45rem,sm:.55rem,xs:.4rem));
      @include res(margin-bottom,.6rem);
    }
    .t3{
      font-family: 'roboto_bold';
      line-height: 1.9;
      border-left: 3px solid $color-main-light;
      @include res(padding-left,.15rem,(md:15px));
    }
  }
  .scroll-icon{
    border: 2px solid $color-main-light;
    position: absolute;
    z-index: 10;
    border-radius: 50px;
    overflow: hidden;
    @include res(transform,translateX(-50%));
    @include res(display,null,(md:none));
    @include res(bottom,.7rem);
    @include res(right,4.16vw,(xs:.3rem));
    @include res(width,18px);
    @include res(height,24px);
    &::after{
      content: '';
      display: block;
      background-color: $color-main-light;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      animation: rain 2s infinite;
      @include res(width,2px);
      @include res(height,6px);
    }
  }
}
@keyframes rain {
  0% {
    transform: translateY(3px);
    opacity: 1;
  }
  80% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.top-circle{
  pointer-events: none;
  .circle{
    position: absolute;
    border-radius: 50%;
    border: 1px solid #2aaaff;
    z-index: 1;
    @include res(transition,all 10s linear,(xs:all 4s linear));
    @include res(top,0,(md:18%,sm:18%,xs:14%));
    @include res(right,0,(md:-30%));
    @include res(width,168.38vw,(md:202.38vw,xs:264.38vw));
    @include res(height,147.76vw,(md:184.76vw,xs:298.76vw));
    &.circle1{
      opacity: .2;
      @include res(transform,translate3d(0%,-59%,0));
    }
    &.circle2{
      opacity: .3;
      @include res(transform,translate3d(-17%,-51%,0));
    }
    &.circle3{
      opacity: .1;
      @include res(transform,translate3d(-25%,-40%,0));
    }
  }
}

.section2{
  position: relative;
  z-index: 6;
  justify-content: space-between;
  @include res(display,flex,(sm:block));
  @include res(padding-top, .6rem);
  @include res(margin-bottom, 1.5rem,(xs:1rem));
  .left{
    flex-shrink: 0;
    font-family: 'roboto_medium';
    @include res(width,33%,(sm:100%));
    @include res(font-size,.7rem,(md:.8rem,sm:.9rem,xs:.6rem));
    @include res(margin-bottom,0,(sm:.6rem));
  }
  .right{
    flex-shrink: 0;
    @include res(width,59.6%,(sm:100%));
    .t{
      line-height: 1.75;
      p{
        @include res(margin-bottom,.4rem);
      }
    }
    .link{
      @include res(margin-top,.5rem);
    }
  }
}

// .section3{
//   position: relative;
//   z-index: 5;
//   @include res(padding-top,0,(md:1rem,xs:.8rem));
//   @include res(padding-bottom,0,(md:1rem,xs:.6rem));
//   .cont{
//     align-items: center;
//     top: 0;
//     left: 0;
//     @include res(display,flex,(md:block));
//     @include res(position,absolute,(md:static));
//     @include res(height, 100vh,(md:auto));
//     &.fixed{
//       @include res(position,fixed,(md:static));
//     }
//     &.abs{
//       top: auto;
//       bottom: 0;
//       @include res(position,absolute,(md:static));
//     }
//     ul{
//       @include res(width,null,(md:calc(100% + .3rem),xs:100%));
//       @include res(flex-wrap,nowrap,(md:wrap));
//       @include res(display,inline-flex,(md:flex,xs:block));
//       li{
//         position: relative;
//         z-index: 1;
//         border-radius: 10px;
//         background: $color-main-dark;
//         border: 1px solid #14396c;
//         overflow: hidden;
//         transition: box-shadow .3s,border-bottom-right-radius .3s,background .3s,transform .3s;
//         @include res(width,21.7vw,(md:calc(33.3% - .3rem),sm:calc(50% - .3rem),xs:100%));
//         @include res(padding,.35rem);
//         @include res(padding-bottom,.6rem);
//         @include res(margin-right,null,(md:.3rem,xs:0));
//         @include res(margin-bottom,null,(md:.3rem));
//         &:not(:last-child){
//           @include res(margin-right,.25rem,(md:.3rem,xs:0));
//         }
//         &:first-child{
//           position: relative;
//           @include res(width,42.7vw,(md:calc(33.3% - .3rem),sm:calc(50% - .3rem),xs:100%));
//           &::after{
//             content: '';
//             display: block;
//             width: 100%;
//             height: 100%;
//             background: url(./images/index_pro_bg.png) no-repeat right center;
//             background-size: cover;
//             position: absolute;
//             top: 0;
//             left: 0;
//             z-index: -1;
//             transition: opacity .3s;
//             @include res(opacity,0,(md:1));
//           }
//           .title{
//             @include res(font-size, .6rem,(md:.7rem,sm:.8rem,xs:.56rem));
//           }
//         }
//         .tag{
//           font-family: 'roboto_light';
//           @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
//           @include res(margin-bottom,.2rem);
//           span{
//             display: inline-block;
//             color: $color-main-light;
//             border: 1px solid $color-main-light;
//             border-radius: 6px;
//             @include res(padding,.06rem .1rem .03rem);
//           }
//         }
//         .title{
//           @include res(font-size, .35rem,(md:.45rem,sm:.55rem,xs:.36rem));
//           @include res(margin-bottom,.2rem,(md:.6rem));
//           @include res(min-height,.84rem,(md:auto));
//         }
//         .img{
//           align-items: center;
//           justify-content: center;
//           transition: transform .3s;
//           @include res(display,flex);
//           @include res(height,14vw,(md:19vw,sm:30vw,xs:56vw));
//           @include res(margin-bottom,null,(md:.4rem));
//           img{
//             max-width: 80%;
//             max-height: 80%;
//             width: auto;
//             height: auto;
//           }
//         }
//         .desc{
//           // flex-wrap: wrap;
//           transition: transform .3s,opacity .3s;
//           // @include res(display,flex,(xs:block));
//           @include res(opacity,0,(md:1));
//           @include res(transform,translateY(100%),(md:translateY(0)));
//           @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
//           @include res(margin-bottom,.3rem,(md:.4rem));
//           p{
//             position: relative;
//             @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
//             // @include res(width,50%,(xs:100%));
//             @include res(padding-left,.15rem,(md:.25rem));
//             @include res(margin-bottom,.05rem);
//             &::after{
//               content: '';
//               display: block;
//               border-radius: 50%;
//               background-color: $color-main-light;
//               position: absolute;
//               left: 0;
//               @include res(top,.07rem,(md:.09rem,sm:.15rem,xs:.11rem));
//               @include res(width,.04rem,(md:4px));
//               @include res(height,.04rem,(md:4px));
//             }
//           }
//         }
//         .link{
//           @include res(position, absolute);
//           @include res(right, .35rem);
//           @include res(bottom, .35rem);
//           .icon{
//             border-radius: 50px;
//             background-color: #fff;
//             color: #000;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             transition: color .3s, background-color .3s;
//             @include res(width,.43rem,(md:.6rem,sm:.78rem,xs:.7rem));
//             @include res(height,.43rem,(md:.6rem,sm:.78rem,xs:.7rem));
//             .iconfont{
//               @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
//             }
//           }
//         }
//         &.hover{
//           &:first-child{
//             &::after{
//               opacity: 1;
//             }
//           }
//           transform: translateX(-.05rem);
//           box-shadow: .07rem .07rem 0 $color-main-light;
//           @include res(border-bottom-right-radius, .6rem);
//           .img{
//             @include res(transform,translateY(-10%),(md:translateY(0)));
//           }
//           .desc{
//             opacity: 1;
//             transform: translateY(0);
//           }
//           .link{
//             .icon{
//               background-color: $color-main-light;
//             }
//           }
//         }
//       }
//     }
//   }
//   .bg{
//     position: absolute;
//     pointer-events: none;
//     border-radius: 100%;
//     z-index: -1;
//     background-image: radial-gradient(circle,rgba(#25327c,1),rgba(#25327c,0));
//     box-shadow: 0 0 5rem rgba(#25327c,.7);
//     // background-image: radial-gradient(circle,rgba(#fff,1),rgba(#fff,0));
//     // box-shadow: 0 0 5rem rgba(#fff,.7);
//     // mix-blend-mode: soft-light;
//     @include res(left,80vw,(sm:55%,xs:30%));
//     @include res(top,-20%,(xs:-13%));
//     @include res(width,6.9rem,(md:8rem,sm:9rem));
//     @include res(height,6.9rem,(md:8rem,sm:9rem));
//   }
//   .circle{
//     .circle1,.circle2{
//       position: absolute;
//       border-radius: 50%;
//       border: 1px solid #2aaaff;
//       z-index: -1;
//       pointer-events: none;
//       @include res(transition,all 10s linear,(xs:all 4s linear));
//       @include res(width,102.5vw,(md:205.5vw));
//       @include res(height,96.45vw,(md:196.45vw));
//     }
//     .circle1{
//       opacity: .2;
//       @include res(top,50%,(md:55%,sm:70%,xs:100%));
//       @include res(left,20vw,(md:10vw,sm:-20vw));
//     }
//     .circle2{
//       opacity: .4;
//       @include res(top,60%,(md:65%,sm:80%,xs:100%));
//       @include res(left,40vw,(md:20vw,sm:-40vw));
//     }
//   }
// }

.section4{
  position: relative;
  z-index: 8;
  overflow: hidden;
  @include res(height,100vh);
  .sec4-title{
    position: absolute;
    font-family: 'roboto_medium';
    z-index: 5;
    @include res(top,7.3vw,(md:28.3vw));
    @include res(left,4.16vw,(xs:.3rem));
    @include res(font-size,.7rem,(md:.8rem,sm:.9rem,xs:.6rem));
  }
  .sect-tab{
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    align-items: center;
    @include res(display,flex,(md:none));
    @include res(width,43.22vw);
    &::before,&::after{
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      background-color: rgba(#25327c, .63);
      pointer-events: none;
      @include res(width,61.66vw);
      @include res(height,96vw);
    }
    &::before{
      backdrop-filter: blur(10px);
      background-color: rgba(#0099ff, .68);
      transition: width .5s,height .5s,transform .5s;
    }
    .text{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      font-family: 'roboto_bold';
      @include res(font-size, .35rem,(md:.45rem,sm:.55rem,xs:.4rem));
      @include res(padding-left,6.7vw);
      a{
        position: relative;
        color: rgba(#fff,.5);
        transition: color .3s;
        @include res(padding-bottom,.1rem);
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          transform: scaleX(0);
          transform-origin: right center;
          transition: transform .3s;
          @include res(height, .03rem,(md:2px));
        }
        &:not(:last-child){
          @include res(margin-bottom, .25rem);
        }
        &.hover{
          color: #fff;
          &::after{
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }
    &.active{
      &::before{
        transform: translateY(-50%) translateX(-2.5%);
        @include res(width,54.42vw);
        @include res(height,81.51vw);
      }
    }
  }
  .swiper{
    height: 100%;
    .swiper-slide{
      position: relative;
      .img{
        height: 100%;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: rgba($color-main-dark, .6);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .text{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        box-sizing: border-box;
        @include res(padding-top,17.96vw,(md:45vw,xs:60vw));
        @include res(padding-left,4.16vw,(xs:.3rem));
        @include res(padding-right,0,(md:4.16vw,xs:.3rem));
        @include res(width,50%,(md:100%));
        .title{
          font-family: 'roboto_bold';
          @include res(font-size, .45rem,(md:.55rem,sm:.65rem,xs:.4rem));
          @include res(margin-bottom,.3rem);
        }
        .desc{
          flex-wrap: wrap;
          line-height: 1.6;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
          @include res(width, 90%,(xs:100%));
          @include res(margin-bottom,.8rem);
          // @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
        }
      }
    }
    .swiper-btn{
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: .7rem;
      right: .3rem;
      @include res(display,none,(md:flex));
      .swiper-button-prev,.swiper-button-next{
        position: static;
        margin: 0 auto;
        border-radius: 50px;
        background-color: #fff;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color .3s, background-color .3s;
        @include res(width,.43rem,(md:.6rem,sm:.78rem,xs:.7rem));
        @include res(height,.43rem,(md:.6rem,sm:.78rem,xs:.7rem));
        &::after{
          font-family: 'iconfont';
          @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        }
        &:hover{
          background-color: $color-main-light;
        }
      }
      .swiper-button-prev{
        @include res(margin-right, .3rem,(xs:.2rem));
        &::after{
          content: '\e746';
        }
      }
      .swiper-button-next{
        &::after{
          content: '\e748';
        }
      }
    }
  }
}

.section5{
  position: relative;
  z-index: 9;
  @include res(padding-top,1.6rem,(xs:.8rem));
  @include res(padding-bottom,1rem,(xs:.6rem));
  .bg{
    position: absolute;
    pointer-events: none;
    border-radius: 100%;
    background-image: radial-gradient(circle,rgba(#25327c,1),rgba(#25327c,0));
    box-shadow: 0 0 5rem rgba(#25327c,.7);
    @include res(left,-20%,(sm:-25%,xs:-50%));
    @include res(top,-20%);
    @include res(width,6.9rem,(md:8rem,sm:9rem));
    @include res(height,6.9rem,(md:8rem,sm:9rem));
  }
  .sec5-title{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include res(margin-bottom,.8rem);
    h2{
      font-family: 'roboto_medium';
      @include res(font-size,.7rem,(md:.8rem,sm:.9rem,xs:.6rem));
    }
    .more{
      line-height: 1.4;
      font-family: 'roboto_medium';
      a{
        display: inline-block;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: $color-main-light;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          transform-origin: right center;
          transition: transform .3s;
          @include res(height, .02rem,(md:2px));
        }
        &:hover{
          &::after{
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }
  }
  ul{
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    li{
      position: relative;
      z-index: 1;
      border-top: 1px solid rgba($color-main-light,.3);
      @include res(width,47.15%,(xs:100%));
      @include res(padding-top,.4rem);
      @include res(padding-bottom,.5rem);
      &::after{
        content: '';
        display: block;
        width: 100%;
        background-color: $color-main-light;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform .3s;
        @include res(height,.02rem,(md:2px));
      }
      .title,.desc{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: transform .3s;
      }
      .title{
        font-family: 'roboto_bold';
        @include res(font-size, .3rem,(md:.4rem,sm:.5rem,xs:.38rem));
        @include res(margin-bottom,.35rem);
      }
      .desc{
        color: rgba(#fff,.5);
        line-height: 1.75;
      }
      &:hover{
        &::after{
          transform-origin: left center;
          transform: scaleX(1);
        }
      }
    }
  }
}